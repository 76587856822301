import React from "react";
import { Container, Row, Col } from "reactstrap";
import GameIcon from "../../assets/images/game-icon.png";
import StoreApp from "../../assets/images/store-apple.png";
import StoreAppDisabled from "../../assets/images/store-apple-disabled.png";
import StoreGoogle from "../../assets/images/store-google.png";

function IndexDownload() {
  return (
    <div
      style={{
        backgroundColor: "#F26D76",
      }}
    >
      <Container style={{ padding: "40px 15px 40px" }}>
        <Row>
          <Col lg="3" md="4" sm="12" className="text-center">
            <img
              className="downloadPartIcon"
              src={GameIcon}
              alt="GameIcon"
              width="233"
              height="233"
            />
          </Col>
          <Col lg="9" md="8" sm="12">
            <h2 className="downloadPartHeader">Emma's mansion</h2>
            <span className="downloadPartText">
              Want to take a break and escape to the countryside? Try our new
              FREE to play puzzle game Emma’s Mansion! You have the chance to
              own a vast mansion provided you renovate it in only 50 days. Dig
              into a story full of romance and adventure. Challenge yourself
              with our exciting puzzles and have lots and lots of fun!
            </span>
          </Col>
        </Row>
        <Row style={{ margin: "35px" }}>
          <Col lg="6" md="12" className="downloadButtonRow text-center">
            <img
              src={StoreAppDisabled}
              alt="StoreApp"
              className="storeButton link-disabled"
              style={{
                borderRadius: "10px",
              }}
            />
          </Col>
          <Col lg="6" md="12" className="downloadButtonRow text-center">
            <a href="https://play.google.com/store/apps/details?id=com.jollificationgames.garden">
              <img
                src={StoreGoogle}
                alt="StoreGoogle"
                className="storeButton"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default IndexDownload;
