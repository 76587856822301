import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import ResourceImage from "../../assets/images/Resources.png";

function IndexSocialize() {
  return (
    <>
      <div
        className="section  section-nucleo-icons"
        style={{ backgroundColor: "#FDE0C0" }}
      >
        <Container>
          <Row>
            <Col lg="6" md="12">
              <img
                src={ResourceImage}
                alt="ResourceImage"
                style={{ width: "100%", height: "90%" }}
              />
            </Col>
            <Col lg="6" md="12" className="text-center">
              <h1 className="screenshotsHeader">Let's socialize!</h1>
              <br />
              <Button
                className="socialButton"
                // href="/nucleo-icons"
                disabled
              >
                <i
                  style={{
                    fontSize: "30px",
                  }}
                  className="fa fa-facebook"
                />
              </Button>
              <Button className="socialButton" disabled>
                <i
                  style={{
                    fontSize: "30px",
                    marginRight: "5px",
                  }}
                  className="fa fa-instagram"
                />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default IndexSocialize;
