import React from "react";
import { Row, Col, Container } from "reactstrap";

function ContactFooter() {
  return (
    <footer className="footer">
      <Container className="pt-5">
        <Row className="charactersRow">
          <Col lg="12" md="12">
            <div className="contactHeader">Based in</div>

            <h2 className="animate-charcter">London</h2>
          </Col>
        </Row>
      </Container>
      <Container className="pt-5 pb-5">
        <Row className="charactersRow">
          <Col lg="6" md="12">
            <span className="contactHeader">Email us</span>
            <div className="contactSubHeader">
              <a href="mailto:contact@emmasmansion.com">
                contact@emmasmansion.com
              </a>
            </div>
          </Col>
          <Col lg="6" md="12">
            <span className="contactHeader">Find us</span>
            <div className="contactSubHeader">
              1 Saints Court, 5 Sylvan Hill, London SE192QB, United Kingdom
            </div>
          </Col>
        </Row>

        <Row className="charactersRow">
          <Col lg="6" md="12">
            <span className="contactHeader">Call Us</span>
            <div className="contactSubHeader">+447786704959</div>
          </Col>
          <Col lg="6" md="12">
            <br />
            <a className="contactSocial link-disabled" href="#">
              <i className="fa fa-facebook-square" />
            </a>
            <a className="contactSocial link-disabled" href="#">
              <i className="fa fa-instagram" />
            </a>
            <a className="contactSocial link-disabled" href="#">
              <i className="fa fa-linkedin" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default ContactFooter;
