import React from "react";
import { Container } from "reactstrap";
import SectionCarousel from "./SectionCarousel";

function IndexScreenshot() {
  return (
    <div
      className="section pt-4"
      style={{
        backgroundColor: "#76C96E",
      }}
    >
      <Container>
        <div className="title-brand mb-5">
          <h2 className="screenshotsHeader">Screenshots</h2>
        </div>
        <SectionCarousel />
      </Container>
    </div>
  );
}

export default IndexScreenshot;
