import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ContactHeader from "components/Headers/ContactHeader";
import ContactFooter from "components/Footers/ContactFooter";

const Contact = () => {
  return (
    <>
      <IndexNavbar />
      <ContactHeader />
      <ContactFooter />
    </>
  );
};

export default Contact;
