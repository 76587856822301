import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/images/Screenshot/S1.png").default,
  },
  {
    src: require("assets/images/Screenshot/S2.png").default,
  },
  {
    src: require("assets/images/Screenshot/S3.png").default,
  },
  {
    src: require("assets/images/Screenshot/S4.png").default,
  },
  {
    src: require("assets/images/Screenshot/S5.png").default,
  },
  {
    src: require("assets/images/Screenshot/S6.png").default,
  },
];

function SectionCarousel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <div id="carousel">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="9">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              interval={3000}
            >
              {items.map((item) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <img src={item.src} alt={item.altText} />
                  </CarouselItem>
                );
              })}

              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              />
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              />
            </Carousel>
          </Col>
        </Row>

        <Row>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
        </Row>
      </Container>
    </div>
  );
}

export default SectionCarousel;
