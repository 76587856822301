import React from "react";

import { Container, Button } from "reactstrap";
import Logo from "../../assets/images/game-logo.png";

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/images/background.png").default + ")",
        }}
      >
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <img src={Logo} alt="Logo" className="logo" />
              <Button className="btn-round" href="https://youtu.be/evJeJ4mJpKs">
                Watch trailer
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
