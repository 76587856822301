import React from "react";

import { Container } from "reactstrap";

function ContactHeader() {
  return (
    <div
      className="page-header section-dark contact-header-visibilty"
      style={{
        backgroundImage:
          "url(" + require("assets/images/contact-us.jpg").default + ")",
      }}
    >
      <div className="filter" />
      <div className="content-center" style={{ marginTop: "0px !important" }}>
        <Container>
          <div className="title-brand">
            <h1
              style={{
                fontFamily: "Lexend",
                fontWeight: "bold",
                fontSize: "60px",
                color: "#FFF",
              }}
            >
              Feel free to get in touch with us
            </h1>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ContactHeader;
