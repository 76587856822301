import React from "react";
import { Container, Row, Col } from "reactstrap";

function SecondSection() {
  return (
    <>
      <div className="section" style={{ backgroundColor: "#6644BB" }}>
        <Container className="mb-5">
          <Row>
            <Col lg="6" md="12">
              <h1
                style={{
                  fontSize: "85px",
                  fontFamily: "Lexend",
                  color: "#fff",
                }}
              >
                About Us
              </h1>
              <br />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <h3
                style={{
                  fontSize: "20px",
                  fontFamily: "Lexend",
                  color: "#fff",
                }}
              >
                Located in the heart of charming Copenhagen, we believe the
                spirit of the city is infused in everything we do.
              </h3>
              <br />
              <h3
                style={{
                  fontSize: "20px",
                  fontFamily: "Lexend",
                  color: "#fff",
                }}
              >
                We value an open and amicable work environment based on
                individual freedom and responsibility. We believe that
                creativity can only truly thrive if you enjoy yourself as well
                as the company of your colleagues. Therefore, we strive to
                nurture a cordial and social atmosphere in which each individual
                is encouraged to grow and develop.
              </h3>
              <br />
              <h3
                style={{
                  fontSize: "20px",
                  fontFamily: "Lexend",
                  color: "#fff",
                }}
              >
                This is why we consider ourselves not only a growing company,
                but also a growing family.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SecondSection;
