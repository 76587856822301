import React from "react";
import { Container, Row, Col } from "reactstrap";

function FirstSection() {
  return (
    <>
      <div className="section" style={{ backgroundColor: "#cccccc" }}>
        <Container className="mb-5">
          <Row>
            <Col lg="6" md="12">
              <h1
                style={{
                  fontSize: "55px",
                  fontFamily: "Lexend",
                  color: "black",
                }}
              >
                We create games with heart.
              </h1>
              <br />
              <h3
                style={{
                  fontSize: "22px",
                  fontFamily: "Lexend",
                  color: "black",
                }}
              >
                Our dedication to finely crafted games with global appeal has
                resulted in highly successful titles like Lily’s Garden, the
                Simon’s Cat game series, and most recently, Penny & Flo.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FirstSection;
