import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AboutHeader from "components/Headers/AboutHeader.js";
import FirstSection from "components/About/FirstSection";
import SecondSection from "components/About/SecondSection";
import Footer from "components/Footers/IndexFooter.js";

const About = () => {
  return (
    <>
      <IndexNavbar />
      <AboutHeader />
      <FirstSection />
      <SecondSection />
      <Footer />
    </>
  );
};

export default About;
