import React from "react";
import { Container, Row, Col } from "reactstrap";
import Emma from "../../assets/images/Characters/C1 Emma Bronte.png";
import Jesse from "../../assets/images/Characters/C2 Jesse Tanner.png";
import William from "../../assets/images/Characters/C3 William Gray.png";
import Rosita from "../../assets/images/Characters/C4 Rosita Redwood.png";
import Ryan from "../../assets/images/Characters/C5 Ryan Cooper.png";
import Henry from "../../assets/images/Characters/C6 Henry Luckman.png";

function Character() {
  return (
    <div className="section pt-4" style={{ backgroundColor: "#8A71A6" }}>
      <Container>
        <div className="title-brand">
          <h1 className="screenshotsHeader">Characters</h1>
        </div>
        <Row className="charactersRow">
          <Col lg="2" md="12" className="text-center">
            <img
              src={Emma}
              alt="Emma"
              width="165"
              height="212"
              className="charactersImage"
            />
          </Col>
          <Col lg="4" md="12">
            <h5 className="charactersName">Emma</h5>
            <span className="charactersDescrip">
              If you’re looking for a good friend who you can count on for
              getting almost anything done then you should definitely meet Emma!
              A young and upcoming designer who is amazingly good at her job.
              She might be a little too sensitive but when she decides to do
              something… She will put her blood, sweat and tears into that and
              she WILL get it done!
            </span>
          </Col>
          <Col lg="2" md="12" className="text-center">
            <img
              src={Jesse}
              alt="Jesse"
              width="165"
              height="212"
              className="charactersImage"
            />
          </Col>
          <Col lg="4" md="12">
            <h5 className="charactersName">Jesse</h5>
            <span className="charactersDescrip">
              The noisy neighbor of your mansion! Jesse’s life is the actual
              definition of messy! But… He’s always so kind and friendly and
              he’s there whenever you need help. Jesse also has a bird named
              Lucy and he’s usually looking for her all the time because he has
              lost her!
            </span>
          </Col>
        </Row>

        <Row className="charactersRow">
          <Col lg="2" md="12" className="text-center">
            <img
              src={William}
              alt="William"
              width="165"
              height="212"
              className="charactersImage"
            />
          </Col>
          <Col lg="4" md="12">
            <h5 className="charactersName">Mr. Gray</h5>
            <span className="charactersDescrip">
              A moody lawyer who is working on your inheritance case. He might
              seem too angry and even intimidating at times but deep down he has
              a big heart full of love and kindness.
            </span>
          </Col>
          <Col lg="2" md="12" className="text-center">
            <img
              src={Rosita}
              alt="Rosita"
              width="165"
              height="212"
              className="charactersImage"
            />
          </Col>
          <Col lg="4" md="12">
            <h5 className="charactersName">Rozita</h5>
            <span className="charactersDescrip">
              Probably… And I repeat “probably” she is the best carpenter in the
              world. Rozita is an old friend of Emma and tries to help you a
              lot. She is nice and kind but she is NOT at all good with men… I
              mean… all of them… And no one knows why!
            </span>
          </Col>
        </Row>

        <Row className="charactersRow">
          <Col lg="2" md="12" className="text-center">
            <img
              src={Ryan}
              alt="Ryan"
              width="165"
              height="212"
              className="charactersImage"
            />
          </Col>
          <Col lg="4" md="12">
            <h5 className="charactersName">Ryan</h5>
            <span className="charactersDescrip">
              Emma’s ex-boyfriend who is a typical rich businessman. But there’s
              not enough money in the world to convince Emma to get back with
              him. He broke her heart into a thousand pieces by cheating on her
              and he’s not even sorry about it! Ryan is too proud of himself and
              also gives himself the right to judge other people on a regular
              basis!
            </span>
          </Col>
          <Col lg="2" md="12" className="text-center">
            <img
              src={Henry}
              alt="Henry"
              width="165"
              height="212"
              className="charactersImage"
            />
          </Col>
          <Col lg="4" md="12">
            <h5 className="charactersName">Henry</h5>
            <span className="charactersDescrip">
              Miss Luckman’s nephew who is trying every illegal way there is to
              swindle you out of your inherited mansion! Beware of this man’s
              deceptive actions. All he thinks about is turning your mansion
              into a big lucrative casino! He also can’t remember anybody’s name
              and has a very poor memory.
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Character;
