import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexDownload from "components/DownloadButtons/IndexDownload";
import IndexScreenshot from "components/Slider/IndexScreenshot";
import IndexCharacters from "components/Character/IndexCharacters";
import IndexSocialize from "components/Socialize/IndexSocialize";
import Footer from "components/Footers/IndexFooter.js";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      <IndexDownload />
      <IndexScreenshot />
      <IndexCharacters />
      <IndexSocialize />
      <Footer />
    </>
  );
}

export default Index;
