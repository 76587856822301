import React from "react";
import { Row, Col, Container } from "reactstrap";
import Logo from "assets/images/game-logo.png";

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row className="charactersRow">
          <Col lg="3" md="12">
            <img src={Logo} alt="Logo" width="180" height="70" />
          </Col>

          <Col lg="3" md="12">
            <h3 className="footerHeaderText">GISP limited</h3>
            <div>
              1 Saints Court, 5 Sylvan Hill, London SE192QB, United Kingdom
            </div>
          </Col>

          <Col lg="3" md="12">
            <h3 className="footerHeaderText">Contact Us</h3>
            <div>
              <a href="mailto:contact@emmasmansion.com">
                contact@emmasmansion.com
              </a>
            </div>
            <div>+447786704959</div>
          </Col>

          <Col lg="3" md="12">
            <h3 className="footerHeaderText">Social</h3>

            <button className="footerSocialButton" disabled>
              <i style={{ fontSize: "20px" }} className="fa fa-instagram" />
            </button>

            <button className="footerSocialButton" disabled>
              <i className="fa fa-facebook" />
            </button>

            <button className="footerSocialButton" disabled>
              <i className="fa fa-linkedin" />
            </button>
          </Col>

          {/* <Col lg="2" md="12">
            <h3 className="footerHeaderText">Legal</h3>
            <div>
              <a href="#" className="legalText">
                Terms of Service
              </a>
            </div>
            <div className="legalText">
              <a href="#">Privacy Policy</a>
            </div>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
