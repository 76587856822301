import React from "react";

// import { Container, Button } from "reactstrap";

function AboutHeader() {
  return (
    <div
      className="page-header section-dark"
      style={{
        backgroundImage:
          "url(" + require("assets/images/about-us.png").default + ")",
      }}
    >
      {/* <div className="filter" />
    <div className="content-center">
      <Container>
        <div className="title-brand">
          <h1
            style={{
              fontFamily: "Lexend",
              fontWeight: "bold",
              fontSize: "60px",
              color: "#FFF",
            }}
          >
            Feel free to get in touch with us
          </h1>
        </div>
      </Container>
    </div> */}
    </div>

    // <>
    //   <div
    //     className="about-header"
    //     style={{
    //       backgroundImage:
    //         "url(" + require("assets/images/about-us.png").default + ")",
    //     }}
    //   >
    //     <div className="content-center">
    //       <Container>
    //         <div className="title-brand">
    //           <Button className="btn-round" style={{ width: "auto" }}></Button>
    //         </div>
    //       </Container>
    //     </div>
    //   </div>
    // </>
  );
}

export default AboutHeader;
